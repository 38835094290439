import { ReactComponent as Icon } from './icon.svg'
import { ReactComponent as Logo } from './logo.svg'
import FavIcon from './favicon.png'
import Cover from './cover.jpg'

import zone from './zone.json'

export default {
    ...zone,
    Icon,
    Logo,
    FavIcon,
    Cover,
}
