import React from 'react'
import ReactDOM from 'react-dom/client'
import { SplashScreen } from '@capacitor/splash-screen'
import * as Sentry from '@sentry/capacitor'

// we need to import the Runtime first while we have LC1 and LC2
import Runtime from '@newspaces/zero-base/Runtime'

import App from '@newspaces/zero-app/App'
import AppContext, { bootstrapAppContext } from '@newspaces/zero-app-context/AppContext'

// eslint-disable-next-line no-unused-vars
import createContext from './context'

const main = async () => {
    await bootstrapAppContext(
        createContext({
            apps: [
                {
                    key: 'app',
                    scopes: ['organisation', 'organisationUser'],
                    import: () => process.env.RUNTIME_TARGET !== 'mobile' && import('./Admin'),
                    isWebOnly: true,
                },
                {
                    key: 'mobile',
                    isGlobal: true,
                    import: () => import('./Mobile'),
                },
                {
                    key: 'docs',
                    isGlobal: true,
                    isWebOnly: true,
                    import: async () => {
                        if (process.env.RUNTIME_TARGET !== 'mobile') {
                            await import('blueid-base/models')
                            return import('@newspaces/zero-api-app')
                        }
                        return null
                    },
                },
            ],
        })
    )

    if (process.env.RUNTIME_TARGET === 'mobile' && process.env.NODE_ENV === 'production') {
        Sentry.init({
            dsn: Runtime.values.SENTRY_MOBILE_DSN,
            release: Runtime.values.SENTRY_MOBILE_RELEASE,
            maxValueLength: 1000,
            dist: Runtime.values.SENTRY_DIST
        })
    }

    let app = null
    if (AppContext.app?.key === 'mobile') {
        // Render our app component directly here for portal as its an independant ionic app
        const AppComponent = AppContext.app.Component
        app = <AppComponent />
    } else {
        app = <App />
    }

    const root = ReactDOM.createRoot(document.querySelector('#root'))
    root.render(app)
    await SplashScreen.hide()
}

main()
