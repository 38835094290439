/* eslint-disable max-len  */
/* eslint-disable no-template-curly-in-string */
export default {
    manageOrganisations: {
        newOrganisation: 'Create new organisation',
        yourOrganisations: 'Your organisations',
        emptySearch: 'No organisations found',
        moveOrganisation: 'Move organisation',
        leaveOrganisation: 'Leave organisation',
        create: {
            label: 'Create a new organisation',
            description: 'A organisation manages all your identites and locks',
            name: 'Name your organisation',
            action: 'Create organisation',
        },
    },
}
