import i18n from '@newspaces/zero-app/i18n'

import 'blueid-base/i18n'

import enLanguage from './languages/en'
import deLanguage from './languages/de'

i18n.languages = ['en', 'de']

i18n.registerLanguage('en', enLanguage)
i18n.registerLanguage('de', deLanguage)

// use english as default
i18n.setDefaultLocale('en', 'en')
i18n.trySetDefaultLocale()

export default i18n
