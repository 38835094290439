import { configure } from '@newspaces/zero-base/libs/mobx'

// THIS MUST BE LOADED FIRST BEFORE ANYTHING ELSE
// eslint-disable-next-line import/order
import './i18n'

import Organisation from 'blueid-base/models/Organisation'
import OrganisationUser from 'blueid-base/models/OrganisationUser'
import UserData from 'blueid-base/models/UserData'

import blueid from './zones/blueid'

configure({
    enforceActions: 'never',
})

export default settings => ({
    ...settings,
    Organisation,
    OrganisationUser,
    UserData,
    zones: [blueid],
    // For mobile we enable sentry directly here on app using sentry/capacitor
    enableSentry: process.env.RUNTIME_TARGET !== 'mobile',
})
